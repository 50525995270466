import Intro from "./components/intro";
import About from "./components/about"
import Skills from "./components/skills";
import Contact from "./components/contact";
import Toggle from "./components/toggle"
import {ThemeContext} from "./context";
import {useContext} from "react";


const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return(
      <div style={{backgroundColor: darkMode ? "#121323" : "white", color: darkMode ? "white" : "#121323"}}>
          < Toggle />
          < Intro />
          < About />
          < Contact />
          < Skills />
      </div>
  )
}


export default App;
