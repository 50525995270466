import "./about.css";
import aboutimg from "../assets/img/aboutimg.png";

const About = () => {
    return(
       <div className="about-container">
           <div className="a-left">
            <div className="a-card bg"></div>
            <div className="a-card">
                <img src={aboutimg} alt="Bri and her dog" className="a-img"/>
            </div>
           </div>
           <div className="a-right">
               <h1 className="a-title">About Me</h1>
                <div className="a-desc"> I am a front-end developer that is pivoting from a background in education. Because of my background, I am motivated to create websites that are easy for anyone to use and value accommodating as many users as possible.
                    <br/>In my free time, I enjoy hiking the Appalachian Mountains with my dogs and working towards my goal of running a marathon.
                </div>
           </div>
       </div>
    )
}

export default About