import"./toggle.css";
import sun from "../assets/img/sun.png";
import moon from "../assets/img/moon.png";
import {useContext} from "react";
import {ThemeContext} from "../context";

const Toggle = () => {
    const theme = useContext(ThemeContext)
    const handleClick = () =>{
       theme.dispatch({type: "TOGGLE"})
    }

    return(
        <div className="toggle-container">
            <img src={sun} className="t-icon" alt="light-mode toggle"/>
            <img src={moon} className="t-icon" alt="dark-mode toggle"/>
            <div className="t-button" onClick={handleClick} style={{left: theme.state.darkMode ? 0 : 25}}></div>


        </div>
    )
}

export default Toggle