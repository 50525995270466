
import  "./skills.css";
import css from "../assets/img/skillicons/css.png";
import html from "../assets/img/skillicons/html.png";
import js from "../assets/img/skillicons/js.png";
import mocha from "../assets/img/skillicons/mocha.png";
import redux from "../assets/img/skillicons/redux.png";
import react from "../assets/img/skillicons/react.png";
import "typeface-inconsolata";

const Skills = () =>{
    return(
        <div className="skills-container">
           <div className="s-wrap">
               <img src={css} alt="CSS icon" className="s-img"/>
               <img src={html} alt="HTML icon" className="s-img"/>
               <img src={js} alt="JavaScript icon" className="s-img"/>
               <h2 className="s-text"> Stack | Skills</h2>
               <img src={mocha} alt="Mocha icon" className="s-img"/>
               <img src={redux} alt="Redux icon" className="s-img"/>
               <img src={react} alt="React icon" className="s-img"/>
           </div>
        </div>
    )
}

export default Skills
