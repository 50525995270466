import "./intro.css";
import profile from "../assets/img/profile.png";
import {useState, useEffect} from "react";
import "typeface-inconsolata";


const Intro = () => {
    const [loopNum, setLoopNum] =useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Web Developer", "Web Designer", "UI/UX Designer" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(50 - Math.random() * 1);
    const period = 1000;



    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => {clearInterval( ticker)};
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fulltext = toRotate[i];
        let updatedText = isDeleting ? fulltext.substring(0, text.length - 1) : fulltext.substring(0, text.length + 1);

        setText(updatedText);

        if(isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText === fulltext){
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(300);
        }

    }



    return(
            <div className = "intro-container">
                <div className= "i-right">
                    <div className="i-img-bg"> </div>
                    <img src={profile} alt= "Illustrated portrait of Bri" className="i-img" />
                </div>
                <div className= "i-left">
                    <div className= "i-left-wrapper">
                        <h2 className="i-greeting">Hello, My Name is</h2>
                        <h1 className="i-name"> Bri Forbes</h1>
                        <div className="i-text"><div className="wrap">{text}</div></div>
                        <div className="i-desc"> I design and develop websites with accessibility and usability in mind. I am passionate about applying UI/UX design concepts to my development projects to give user's a seamless experience  </div>
                    </div>
                </div>
            </div>


    )
}

export default Intro
